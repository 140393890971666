a[target="_blank"]::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
}

#app-div {
  width: 90vw;
}

#sd-div img {
  width: 30vh;
  height: 40vh;
}

.nav-item:hover {
  background-color: aliceblue;
}

@media (max-width: 991px) {
  #sidebar-div {
    display: none !important;
    visibility: hidden;
  }
}